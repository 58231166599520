<template>
  <div class="student wrap px-10 py-5">
    <div class="d-flex flex-wrap align-end mb-7">
      <div class="d-flex align-center">
        <v-avatar size="64" color="primary">
          <v-img v-if="data.picture" :src="data.picture"></v-img>
          <v-icon v-else color="white">mdi-account mdi-48px</v-icon>
        </v-avatar>
        <div class="ms-6">
          <div class="h4">{{ data.first_name }} {{ data.last_name }}</div>
          <div class="h8 mt-1" v-if="data.city">{{ data.city.name }}, {{ data.country.name }}</div>
          <!-- <div class="h8 gray--text">UTC/GMT +{{ -new Date().getTimezoneOffset() / 60 }} hours</div> -->
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="openMsg(data.id)" depressed width="104" height="34" class="primary mt-2">
        <img height="16" class="me-1" src="@/assets/icon/msgBtn.svg" /> MESSAGE
      </v-btn>
      <!-- <v-btn v-if="data.next_lesson" depressed width="117" height="34" class="primary ms-3">
        <img height="16" class="me-1" src="@/assets/icon/video.svg" /> VIDEO CALL
      </v-btn> -->
    </div>
    <v-divider></v-divider>
    <div class="d-flex flex-sm-row flex-column">
      <div class="column pe-sm-5 pt-6">
        <div class="h4 mb-4">Schedule</div>
        <v-card flat v-for="item in schedule" :key="item.id" class="pa-4 d-flex align-center flex-wrap mt-2" color="#6290dc">
          <div>
            <div class="h9 white--text mb-1">Course title</div>
            <div class="h12 white--text">{{ item.course.title }}</div>
          </div>
          <v-spacer></v-spacer>
          <div class="h10 text-end ms-auto">
            <div class="white--text mb-1">
              {{ new Date(item.begin_at).toLocaleString('en-US', { weekday: 'long', month: 'short', day: 'numeric' }) }}
            </div>
            <div class="white--text">
              {{ new Date(item.begin_at).toLocaleTimeString('en-GB').substring(0, 5) }} -
              {{ new Date(item.end_at).toLocaleTimeString('en-GB').substring(0, 5) }}
            </div>
          </div>
          <v-divider vertical color="white" class="ms-4 me-1"></v-divider>
          <v-btn @click="openModal(item.booking_lesson_id)" icon class="px-0">
            <v-img height="16" max-width="16" contain class="filter-white" src="@/assets/icon/edit.svg"></v-img>
          </v-btn>
        </v-card>
      </div>
      <v-divider vertical></v-divider>
      <div class="column ps-sm-5 pt-6">
        <div class="h4 mb-4">Booked courses</div>
        <v-card v-for="item in courses" :key="item.id" tile flat outlined class="pa-4">
          <div class="mb-2 d-flex align-center">
            <div class="h14 text--text">{{ item.course.category.title }}</div>
            <v-spacer></v-spacer>
            <v-progress-linear
              style="max-width: 40%"
              background-color="hover"
              color="success"
              height="8"
              :value="Math.ceil((item.lessons_finished / item.lessons_total) * 100)"
            ></v-progress-linear>
            <div class="h10 ms-1">{{ item.lessons_finished }}/{{ item.lessons_total }}</div>
          </div>
          <div class="h4">{{ item.course.title }}</div>
          <div class="h10 mb-6">{{ item.course.short_description }}</div>
          <div v-for="t in item.trial_lessons" :key="t.id" class="d-flex h10 mt-1">
            <div>Trial lessons: {{ t.title }}</div>
            <v-spacer></v-spacer>
            <div v-if="t.begin_at">{{ new Date(t.begin_at).toLocaleString().substring(0, 17) }}</div>
          </div>
          <div v-for="j in item.lessons" :key="j.id" class="d-flex h10 mt-1">
            <div>Lessons: {{ j.title }}</div>
            <v-spacer></v-spacer>
            <div v-if="j.begin_at">{{ new Date(j.begin_at).toLocaleString().substring(0, 17) }}</div>
          </div>
          <div v-for="e in item.additional_lessons" :key="e.id" class="d-flex h10 mt-1">
            <div>Additional lessons: {{ e.title }}</div>
            <v-spacer></v-spacer>
            <div v-if="e.begin_at">{{ new Date(e.begin_at).toLocaleString().substring(0, 17) }}</div>
          </div>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="modal" width="568">
      <v-card class="pa-6">
        <div class="h2 mb-4">Edit lesson</div>
        <div class="h10 mt-n2">Date<span class="error--text">*</span></div>
        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="field44"
              v-model="date"
              append-icon="mdi-calendar-month-outline"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              color="secondary"
              height="44"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            :min="new Date().toISOString().substr(0, 10)"
            v-model="date"
            no-title
            @input="(menu = false), getDateList()"
          ></v-date-picker>
        </v-menu>
        <div class="h10 mt-n2">Time<span class="error--text">*</span></div>
        <v-select
          class="field44"
          v-model="time"
          :items="dateList"
          item-text="begin_time"
          item-value="id"
          :item-disabled="(item) => !item.available"
          append-icon="mdi-chevron-down mdi-18px"
          outlined
          dense
          :disabled="!date"
          color="secondary"
          height="44"
        >
          <template v-slot:selection="{ item }">{{ item.begin_time }} - {{ item.end_time }}</template>
          <template v-slot:item="{ item }">{{ item.begin_time }} - {{ item.end_time }}</template>
        </v-select>
        <div class="text-right">
          <v-btn @click="modal = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="editLesson" :disabled="!time" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      menu: false,
      week: new Date().getMonth(),
      year: new Date().getFullYear(),
      bookingId: '',
      date: null,
      time: '',
    };
  },
  mounted() {
    this.$store.dispatch('getStudentItem');
    this.$store.dispatch('getStudentSchedule');
    this.$store.dispatch('getStudentCourses');
  },
  methods: {
    openModal(id) {
      this.bookingId = id;
      this.modal = true;
    },
    openMsg(id) {
      this.$store.dispatch('newMsg', { student: id });
    },
    async getDateList() {
      await this.$store.dispatch('getAvailableWindowsList', this.date);
    },
    async editLesson() {
      const data = new Object();
      data.window = this.time;
      data.date = this.date;
      await this.$store.dispatch('editStudentLesson', { id: this.bookingId, data: data }).then(() => {
        this.bookingId = '';
        this.date = null;
        this.time = '';
        this.modal = false;
        this.$store.dispatch('getStudentSchedule');
        this.$store.dispatch('getStudentCourses');
      });
    },
  },
  watch: {
    modal(val) {
      val || (this.date = null);
    },
  },
  computed: {
    data() {
      return this.$store.getters.studentItem;
    },
    schedule() {
      return this.$store.getters.studentSchedule;
    },
    courses() {
      return this.$store.getters.studentCourses;
    },
    dateList() {
      return this.$store.getters.availableList.windows;
    },
  },
  destroyed() {
    this.$store.dispatch('setStudentItem', {});
    this.$store.dispatch('setStudentSchedule', []);
    this.$store.dispatch('setStudentCourses', []);
    this.$store.dispatch('setAvailableWindowsList', { windows: [] });
  },
};
</script>

<style lang="scss">
.student {
  .column {
    max-width: 50%;
    width: 100%;
  }
  @media screen and (max-width: 599px) {
    .column {
      max-width: initial;
    }
  }
}
</style>
